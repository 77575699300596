<div layout="column" layout-align="center center" layout-fill="layout-fill">

  <div fxLayout="column" fxLayoutAlign="center center">
    <div style="max-width: 770px; margin: 10px;">
      <p style="margin-left: 0px; margin-top: 20px; horiz-align: left;">
        {{ "By clicking on the 'Sign in to Amazon to get the Seller Id and MWS Token' button," | translate }}<br />
        {{ 'you can authorise us to download reports automatically directly from Amazon.' | translate }}
      </p>

      <p style="margin-left: 10px;">
        <img src="{{ 'https://verilistorage2.blob.core.windows.net/userguide/AMAZON_Auth.png' | translate}}" width="91%" height="100%" alt="{{ 'Step 1' | translate }}" title="{{ 'Step 1' | translate }}" />
      </p>

      <p style="margin-left: 0px; margin-top: 20px; horiz-align: left;">{{ 'Please select the required checkbox as shown below to continue:' | translate }}</p>

      <p style="margin-left: 10px">
        <img src="{{ 'https://verilistorage2.blob.core.windows.net/userguide/AMAZON_ClickNext.png' | translate}}" width="91%" height="100%" alt="{{ 'Step 2' | translate }}" title="{{ 'Step 2' | translate }}"/>
      </p>

      <p style="margin-left: 0px; margin-top: 20px; horiz-align: left;">
        {{ 'After the successful authorisation please copy the Seller Id and custom MWS Authorisation Token (highlighted below) and paste into the registration form.' | translate }}
      </p>

      <p style="margin-left: 10px">
        <img src="{{ 'https://verilistorage2.blob.core.windows.net/userguide/AMAZON_Congrat.png' | translate}}" width="91%" height="100%" alt="{{ 'Step 3' | translate }}" title="{{ 'Step 3' | translate }}" />
      </p>
    </div>
  </div>
</div>
