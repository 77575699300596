export const LANG_DE_NAME = 'de';

export const LANG_DE_TRANS = {
  'https://sellercentral.amazon.co.uk/gp/mws/registration/register.html?signInPageDisplayed=1&devAuth=1&developerName=Vescati%20(Verili)&devMWSAccountId=3990-8830-8654': 'https://sellercentral.amazon.de/gp/mws/registration/register.html?signInPageDisplayed=1&devAuth=1&developerName=Vescati%20(Verili)&devMWSAccountId=3990-8830-8654',
  'https://verilistorage2.blob.core.windows.net/userguide/AMAZON_Auth.png': 'https://verilistorage2.blob.core.windows.net/userguide/AMAZON_Auth-de.png',
  'https://verilistorage2.blob.core.windows.net/userguide/AMAZON_ClickNext.png': 'https://verilistorage2.blob.core.windows.net/userguide/AMAZON_ClickNext-de.png',
  'https://verilistorage2.blob.core.windows.net/userguide/AMAZON_Congrat.png': 'https://verilistorage2.blob.core.windows.net/userguide/AMAZON_Congrat-de.png',
  'Username': 'Benutzername',
  'Password': 'Passwort',
  'New password': 'Neues Passwort',
  'Email or username': 'E-mail oder Benutzername',
  'Please login using either your email or the username provided': 'Bitte loggen Sie sich entweder mit Ihrer E-Mail oder mit dem bereitgestellten Benutzernamen ein.',
  'Confirm new password': 'Bestätigen Sie das neue Passwort',
  'Passwords must match': 'Kennwörter müssen übereinstimmen',

  "connectingMessageWithSalesChannel": "Verbindung wird hergestellt...",
  'processingMessageWithSalesChannel': 'Verarbeite Ihre <strong>%s</strong> Berechtigung...',
  'completedMessageWithSalesChannel': 'Ihr <strong>%s</strong> Berechtigungsprozess ist jetzt abgeschlossen!',
  "connectedMessage": "Ihr Konto ist verbunden",
  'connectedMessageWithoutAccountancy': '"Ihr Shop ist verbunden. Bitte kontaktieren Sie uns, um ein Konto zu erstellen.',
  'Business name': 'Unternehmensname',
  'Accountancy': 'Buchhaltung',
  'Accountant': 'Buchhalter',
  'failedMessage': 'Der <strong>%s</strong> Berechtigungsprozess ist fehlgeschlagen',
  'contactYourAccountantMessage': 'Bitte kontaktieren Sie Ihren Buchhalter <strong>%s</strong> bei <strong>%s</strong>.',
  'invalidTokenMessage': '(Der angegebene Token ist nicht gültig)',
  'failedToAcquireTokenMessage': '(Fehler beim Erwerb von %s Token)',
  'Email': 'E-mail',
  'Company name': 'Unternehmen',
  'Amazon Seller Id / Merchant Token': 'Amazon-Verkäufer-ID',
  'Register': 'Registrieren',
  'Login': 'Anmeldung',
  'Registration': 'Registrierung',
  'Amazon Connection Registration': 'Registrierung der Amazon- Verbindung',
  'Please enter an email address': 'Bitte geben Sie die E-Mail-Adresse ein',
  'Email address is not valid': 'E-Mail-Adresse ist ungültig',
  'Please enter the company name': 'Bitte geben Sie den Firmennamen ein',
  'Please enter an Amazon Seller Id': 'Bitte geben Sie die Amazon-Verkäufer-ID ein',
  'Already registered': 'Schon registriert',
  'Registered successfully': 'Erfolgreich registriert',
  'Updated successfully': 'Erfolgreich aktualisiert',
  'MWS authorisation token': 'MWS- Autorisierungstoken',
  'Update': 'Aktualisieren',
  'connection failed': 'Verbindung fehlgeschlagen',
  'Invalid Seller Id / MWS Token combination': 'Ungültige Verkäufer-ID / MWS-Token-Kombination',
  'DATA USAGE': 'DATENVERBRAUCH',
  'We take data usage and storage very seriously. We only access data from your Amazon account that is absolutely necessary in order to process your international VAT.': 'Wir nehmen die Nutzung und Speicherung von Daten sehr ernst.Wir greifen nur auf Daten von Ihrem Amazon- Konto zu, die für die Verarbeitung Ihrer internationalen Mehrwertsteuer unbedingt erforderlich sind.',
  'Data we access:': 'Daten, auf die wir zugreifen:',
  'Amazon VAT Transactions Report': 'Amazon MwSt-Transaktionsbericht',
  'This is the information necessary for us to compile your VAT liability and is just your sales data that you can freely download from Amazon in their report.': 'Dies sind die Informationen, die wir zur Erstellung Ihrer Mehrwertsteuerpflicht benötigen, und nur Ihre Verkaufsdaten, die Sie in ihrem Bericht frei von Amazon herunterladen können.',
  'AMAZON CONNECTION': 'AMAZON-VERBINDUNG',
  'Sign in to Amazon to get the Seller Id and MWS Token': 'Melden Sie sich bei Amazon an, um die Verkäufer-ID und den MWS-Token zu erhalten',
  'Step 1': 'Schritt 1',
  "By clicking on the 'Sign in to Amazon to get the Seller Id and MWS Token' button,": "Durch Klicken auf die Schaltfläche 'Anmelden bei Amazon, um die Verkäufer- ID und MWS-Token zu erhalten",
  "you can authorise us to download reports automatically directly from Amazon EU.": "Sie können uns autorisieren, Berichte automatisch direkt aus Amazon EU herunterzuladen.",
  'Step 2': 'Schritt 2',
  'Please select the required checkbox as shown below to continue:': 'Bitte aktivieren Sie die erforderlichen Kontrollkästchen, wie unten gezeigt, um fortzufahren:',
  'Step 3': 'Schritt 3',
  'After the successful authorisation please copy the Seller Id and custom MWS Authorisation Token (highlighted below) and paste into the registration form.': 'Nach der erfolgreichen Autorisierung kopieren Sie bitte die Verkäufer-ID und das benutzerdefinierte MWS-Autorisierungstoken (siehe unten) und fügen Sie sie in das Registrierungsformular ein.',
};
