export const LANG_CS_NAME = 'cs';

export const LANG_CS_TRANS = {
  'https://sellercentral.amazon.co.uk/gp/mws/registration/register.html?signInPageDisplayed=1&devAuth=1&developerName=Vescati%20(Verili)&devMWSAccountId=3990-8830-8654': 'https://sellercentral.amazon.co.uk/gp/mws/registration/register.html?signInPageDisplayed=1&devAuth=1&developerName=Vescati%20(Verili)&devMWSAccountId=3990-8830-8654',
  'https://verilistorage2.blob.core.windows.net/userguide/AMAZON_Auth.png': 'https://verilistorage2.blob.core.windows.net/userguide/AMAZON_Auth.png',
  'https://verilistorage2.blob.core.windows.net/userguide/AMAZON_ClickNext.png': 'https://verilistorage2.blob.core.windows.net/userguide/AMAZON_ClickNext.png',
  'https://verilistorage2.blob.core.windows.net/userguide/AMAZON_Congrat.png': 'https://verilistorage2.blob.core.windows.net/userguide/AMAZON_Congrat.png',
  'Username': 'Username',
  'Password': 'Password',
  'New password': 'New password',
  'Email or username': 'Email or username',
  'Please login using either your email or the username provided': 'Please login using either your email or the username provided',
  'Confirm new password': 'Confirm new password',
  'Passwords must match': 'Passwords must match',

  'Email': 'Email',
  'Company name': 'Company name',
  'Amazon Seller Id / Merchant Token': 'Amazon Seller Id / Merchant Token',
  'Register': 'Register',
  'Login': 'Login',
  'Registration': 'Registration',
  'Amazon Connection Registration': 'Amazon Connection Registration',
  'Please enter an email address': 'Please enter an email address',
  'Email address is not valid': 'Email address is not valid',
  'Please enter the company name': 'Please enter the company name',
  'Please enter an Amazon Seller Id': 'Please enter an Amazon Seller Id',
  'Already registered': 'Already registered',
  'Registered successfully': 'Registration successful',
  'Updated successfully': 'Updated successfully',
  'MWS authorisation token': 'MWS authorisation token',
  'Update': 'Update',
  'connection failed': 'connection failed',
  'Invalid Seller Id / MWS Token combination': 'Invalid Seller Id / MWS Token combination',
  'DATA USAGE': 'DATA USAGE',
  'We take data usage and storage very seriously. We only access data from your Amazon account that is absolutely necessary in order to process your international VAT.': 'We take data usage and storage very seriously.We only access data from your Amazon account that is absolutely necessary in order to process your international VAT.',
  'Data we access:': 'Data we access:',
  'Amazon VAT Transactions Report': 'Amazon VAT Transactions Report',
  'This is the information necessary for us to compile your VAT liability and is just your sales data that you can freely download from Amazon in their report.': 'This is the information necessary for us to compile your VAT liability and is just your sales data that you can freely download from Amazon in their report.',
  'AMAZON CONNECTION': 'AMAZON CONNECTION',
  'Sign in to Amazon to get the Seller Id and MWS Token': 'Sign in to Amazon to get the Seller Id and MWS Token',
  'Step 1': 'Step 1',
  "By clicking on the 'Sign in to Amazon to get the Seller Id and MWS Token' button,": "By clicking on the 'Sign in to Amazon to get the Seller Id and MWS Token' button,",
  "you can authorise us to download reports automatically directly from Amazon EU.": 'you can authorise us to download reports automatically directly from Amazon EU.',
  'Step 2': 'Step 2',
  'Please select the required checkbox as shown below to continue:': 'Please select the required checkbox as shown below to continue:',
  'Step 3': 'Step 3',
  'After the successful authorisation please copy the Seller Id and custom MWS Authorisation Token (highlighted below) and paste into the registration form.': 'After the successful authorisation please copy the Seller Id and custom MWS Authorisation Token (highlighted below) and paste into the registration form.',
};
