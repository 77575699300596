<div layout="column" layout-align="center center" layout-fill="layout-fill" style="height: 100vh;">

  <div fxLayout="column" fxLayoutAlign="center center" style="background-color: dimgrey; height: 100vh;">
    <mat-card class="login-card">
      <mat-card-header>
        <mat-card-title>{{ 'Login' | translate }}</mat-card-title>
      </mat-card-header>

      <mat-card-content>
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <p mat-line> {{ 'Please login using either your email or the username provided' | translate}} </p>
          </div>

          <div class="form-group">
            <mat-form-field floatLabel>
              <input matTooltip="{{ 'Email or username' | translate }}" matInput placeholder="{{ 'Email or username' | translate }}" [formControl]="f.username">
              <mat-error *ngIf="f.username.invalid">{{getUsernameErrorMessage()}}</mat-error>
            </mat-form-field>
          </div>
          <div class="form-group">
            <mat-form-field floatLabel>
              <input type="password" matInput placeholder="{{ 'Password' | translate }}" [formControl]="f.password">
              <mat-error *ngIf="f.password.invalid">{{getPasswordErrorMessage()}}</mat-error>
            </mat-form-field>
          </div>
          <div class="form-group">
            <button [disabled]="loading" class="btn btn-primary">{{ 'Login' | translate }}</button>
            <img *ngIf="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
</div>
