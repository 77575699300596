  import { NgModule } from '@angular/core';
  import { Routes, RouterModule } from '@angular/router';

  import { HomeComponent } from './home/home.component';
  import { ApplicationComponent } from './application/application.component';
  import { ConnectComponent } from './connect/connect.component';
  import { HelpComponent } from './help/help.component';
  import { LoginComponent } from './login/login.component';
  import { AuthGuard } from './_guards/auth.guard';

  const routes: Routes = [
    //{ path: 'registration', component: HomeComponent, canActivate: [AuthGuard] },
    //{ path: 'login', component: LoginComponent },
    //{ path: '', redirectTo: '/registration', pathMatch: 'full' },

    //{ path: '', component: HomeComponent, canActivate: [AuthGuard] },
    { path: 'registration/:username', component: HomeComponent, canActivate: [AuthGuard] },
    { path: 'registration/:language/:username', component: HomeComponent, canActivate: [AuthGuard] },
    { path: 'application/:username', component: ApplicationComponent, canActivate: [AuthGuard] },
    { path: 'application/:language/:username', component: ApplicationComponent, canActivate: [AuthGuard] },
    { path: 'connect/:username', component: ConnectComponent, canActivate: [AuthGuard] },
    { path: 'connect/:language/:username', component: ConnectComponent, canActivate: [AuthGuard] },
    { path: 'login', component: LoginComponent },
    { path: 'help', component: HelpComponent },

    //{ path: '**', redirectTo: '' }
  ];

  @NgModule({
    imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy : 'always' })],
    exports: [RouterModule]
  })
  export class AppRoutingModule { }
