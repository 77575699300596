import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private languageChanged = new Subject<string>();

  languageChanged$ = this.languageChanged.asObservable();

  constructor() { }

  changeLanguage(language: string) {
    this.languageChanged.next(language);
  }
}
