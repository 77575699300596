import { Component, OnInit, Inject, OnDestroy  } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, AsyncValidatorFn, AbstractControl } from '@angular/forms';
import { first, map, catchError } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

import { ClientService } from '../_services/client.service';
import { TranslateService } from '../_translations/translate.service';
import { LanguageService } from "../_services/languag.service";
import { UserData } from '../_providers/userData';
import { MessageResult } from '../_models/messageResult';
import { ClientResult } from '../_models/clientResult';
import { Client } from '../_models/client';
import { AmazonConnectionResult } from '../_models/amazonConnectionResult';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { InformationOverlayService } from '../_services/information-overlay.service';
import { InformationOverlayRef } from '../_services/information-overlay-ref';
import { AmazonConnectionInformationResult } from "../_models/amazonConnectionInformationResult";
import { XeroConnectionInformationResult } from "../_models/xeroConnectionInformationResult";
import { ShopifyConnectionInformationResult } from "../_models/shopifyConnectionInformationResult";
import * as util from 'util';
import { RegistrationResponseResult } from "../_models/registrationResponseResult";
import { WhitelabelService } from "../_services/whitelabel.service";
import { forEach } from '@angular/router/src/utils/collection';

@Component({
  selector: 'app-home',
  templateUrl: './connect.component.html',
  styleUrls: ['./connect.component.css']
})
export class ConnectComponent implements OnInit, OnDestroy  {
  public baseUrl: string;
  submitted = false;
  canSubmit = false;
  completed: boolean;
  newClient = true;
  accountant: string;
  language: string;
  companyInvalid: boolean;
  sellerId: string;
  mwsToken: string;
  spApiCode: string;
  shopifyCode: string;
  shopifyShop: string;
  xeroCode: string;
  xeroCodeVerifier: string;

  private sub: any;
  private languageSub: any;
  private whitelabelSub: any;

  requestProcessing = true;
  requestSuccessful = false;
  requestFailed = false;
  showBusinessName = true;
  showDetails = false;
  showContactUsLink = false;

  customErrorMessage = false;

  authorisationTarget = '';
  marketplace = '';

  salesChannel = "";
  salesChannelLogo = "";
  logoHeight = 50.0;
  logoWidth = 166.0;

  fontFamily = "Arial";
  fontColor = "#083d5a";
  highlightColor = "title";
  contactUsLink = "";

  clientId = "";
  company = "";

  accountancyName = "";
  accountantName = "";
  businessName = "";

  processingMessage = "";
  completedMessage = "";
  failedMessage = "";
  contactYourAccountantMessage = "";

  constructor(
    private route: ActivatedRoute,
    private clientService: ClientService,
    private translateService: TranslateService,
    private languageService: LanguageService,
    private whitelabelService: WhitelabelService,
    private formBuilder: FormBuilder,
    public userData: UserData,
    @Inject('BASE_URL') baseUrl: string) {
    this.baseUrl = baseUrl;

    this.whitelabelSub = this.whitelabelService.layoutChanged$.subscribe(language => { this.updateLayout(); });

    this.route.params.subscribe(params => {

      var urlParts = params['username'].toLowerCase().split('?');

      var authorisationTargetParts = urlParts[0].split('-');
      this.authorisationTarget = authorisationTargetParts[0];

      if (authorisationTargetParts.length > 1) {
        this.marketplace = authorisationTargetParts[1];
      }
      else {
        this.marketplace = null;
      }

      if (this.authorisationTarget.startsWith('xero')) {
        this.authorisationTarget = "xero";
        this.salesChannel = "Xero";
        this.salesChannelLogo = "assets/Xero_logo_black.svg";
        this.logoHeight = 120;
        this.logoWidth = 120;
      }
      else if (this.authorisationTarget.startsWith('amazon')) {
        this.authorisationTarget = "amazon";
        this.salesChannel = "Amazon";
        this.salesChannelLogo = "assets/Amazon_logo_black.svg";
        this.logoHeight = 50;
        this.logoWidth = 166;
      }

      else if (this.authorisationTarget.startsWith('shopify')) {
        this.authorisationTarget = "shopify";
        this.salesChannel = "Shopify";
        this.salesChannelLogo = "assets/Shopify_logo_black.svg";
        this.logoHeight = 50;
        this.logoWidth = 170;
      }

      urlParts[1].split('&').forEach(p => {
        if (p.startsWith("state=")) {
          this.company = p.split('=')[1].split('~')[0];
        }
      });

      this.whitelabelService.updateLayoutFromCompany(this.company);
    });
  }

  ngOnInit() {

    console.log('ConnectComponent initializing...');

    this.completed = true;

    this.sub = this.route.params.subscribe(params => {
      this.accountant = params['username'];
      this.language = params['language'];

      if (this.language == null) {
        this.language = 'en';
      }

      this.translateService.use(this.language);

      this.languageSub = this.languageService.languageChanged$.subscribe(language => { this.updateTranslation(); });

      this.processingMessage = util.format(this.translateService.instant('processingMessageWithSalesChannel'), this.salesChannel);
      this.completedMessage = util.format(this.translateService.instant('completedMessageWithSalesChannel'), this.salesChannel);
      this.failedMessage = this.translateService.instant('failedMessage');

      var localUrlPieces = this.route.snapshot['_routerState'].url.toString().split('/');
      var urlParameters = localUrlPieces[localUrlPieces.length - 1];;

      console.log(`Parameters: ${urlParameters}`);

      var queryParams = urlParameters.split(/%3F/g)[1].split('&');

      var updatedUrlParameters = '';
      for (const param of queryParams) {
        var [key, value] = param.split(/%3D/g);

        var decodedValue = decodeURIComponent(value);
        var decodedKey = decodeURIComponent(key);

        console.log(`Key: ${decodedKey}, Value: ${decodedValue}`);

        if (decodedKey.toLowerCase() === 'timestamp') {
          continue;
        }

        if (decodedValue === 'undefined') {
          updatedUrlParameters += `${decodedKey}&`
        }
        else {
          updatedUrlParameters += `${decodedKey}%3D${decodedValue}&`
        }
      }

      urlParameters = this.authorisationTarget + '%3F' + updatedUrlParameters.slice(0, -1)

      console.log(`Parameters: ${urlParameters}`);

      if (this.authorisationTarget === 'amazon') {

        this.clientService.getAmazonRegistration(urlParameters)
          .pipe(first())
          .subscribe(
            data => {
              var result = data as AmazonConnectionInformationResult;
              var amazonConnection = result.result;

              if (amazonConnection != null) {
                this.sellerId = amazonConnection.sellerId;
                this.mwsToken = amazonConnection.mwsToken;
                this.spApiCode = amazonConnection.spApiCode;
                this.accountant = amazonConnection.clientId;
                
                if (amazonConnection.businessName === '') {
                  this.showBusinessName = false;
                }

                if (amazonConnection.marketplace !== '') {
                  this.salesChannel = "Amazon (" + amazonConnection.marketplace + ")";
                }

                this.businessName = amazonConnection.businessName;
                this.accountancyName = amazonConnection.accountancy;
                this.clientId = amazonConnection.accountant;
                this.accountantName = amazonConnection.accountant;

                this.contactYourAccountantMessage = util.format(this.translateService.instant('contactYourAccountantMessage'), this.accountantName, this.accountancyName);
              }
            },
            error => {
              this.customErrorMessage = true;
              this.contactYourAccountantMessage = this.translateService.instant('invalidTokenMessage');
              this.requestProcessing = false;
              this.requestFailed = true;
              this.showDetails = true;
            },
            () => {
              this.complete();
            });
      }
      else if (this.authorisationTarget === 'xero') {

        this.clientService.getXeroRegistration(urlParameters)
          .pipe(first())
          .subscribe(
            data => {
              var result = data as XeroConnectionInformationResult;
              var xeroConnection = result.result;

              if (xeroConnection != null) {
                this.sellerId = xeroConnection.clientId;
                this.mwsToken = xeroConnection.code;

                this.xeroCode = xeroConnection.code;
                this.clientId = xeroConnection.clientId;
                this.accountant = xeroConnection.state;
                this.xeroCodeVerifier = xeroConnection.state;

                if (xeroConnection.businessName === '') {
                  this.showBusinessName = false;
                }

                this.businessName = xeroConnection.businessName;
                this.accountancyName = xeroConnection.accountancy;
                this.accountantName = xeroConnection.accountant;

                this.contactYourAccountantMessage = util.format(this.translateService.instant('contactYourAccountantMessage'), this.accountantName, this.accountancyName);
              }
            },
            error => {
              this.customErrorMessage = true;
              this.contactYourAccountantMessage = this.translateService.instant('invalidTokenMessage');
              this.requestProcessing = false;
              this.requestFailed = true;
              this.showDetails = true;
            },
            () => {
              this.complete();
            });
      }
      else if (this.authorisationTarget === 'shopify') {

        this.clientService.getShopifyRegistration(urlParameters)
          .pipe(first())
          .subscribe(
            data => {
              var result = data as ShopifyConnectionInformationResult;
              var shopifyConnection = result.result;

              if (shopifyConnection != null) {
                this.sellerId = shopifyConnection.clientId;
                this.mwsToken = shopifyConnection.code;

                this.shopifyCode = shopifyConnection.code;
                this.shopifyShop = shopifyConnection.shop;
                this.clientId = shopifyConnection.clientId;
                this.accountant = shopifyConnection.state;

                if (shopifyConnection.businessName === '') {
                  this.showBusinessName = false;
                }

                this.businessName = shopifyConnection.businessName;
                this.accountancyName = shopifyConnection.accountancy;
                this.accountantName = shopifyConnection.accountant;

                this.contactYourAccountantMessage = util.format(this.translateService.instant('contactYourAccountantMessage'), this.accountantName, this.accountancyName);
              }
            },
            error => {
              this.customErrorMessage = true;
              this.contactYourAccountantMessage = this.translateService.instant('invalidTokenMessage');
              this.requestProcessing = false;
              this.requestFailed = true;
              this.showDetails = true;
            },
            () => {
              this.complete();
            });
      }
  });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    this.languageSub.unsubscribe();
  }
  
  updateLayout() {
    this.fontColor = this.whitelabelService.fontColor;
    this.fontFamily = this.whitelabelService.fontFamily;
    this.highlightColor = this.whitelabelService.highlightColor;
    this.contactUsLink = this.whitelabelService.contactUsLink;
  }

  updateTranslation()
  {
    this.processingMessage = util.format(this.translateService.instant('processingMessageWithSalesChannel'), this.salesChannel);
    this.completedMessage = util.format(this.translateService.instant('completedMessageWithSalesChannel'), this.salesChannel);
    this.failedMessage = this.translateService.instant('failedMessage');

    if (this.customErrorMessage === false)
    {
      this.contactYourAccountantMessage = util.format(this.translateService.instant('contactYourAccountantMessage'), this.accountantName, this.accountancyName);
    }
  }

  complete() {

    const client: Client = new Client();

    client.isUpdate = !this.newClient;

    client.amazonSellerId = this.sellerId;
    client.amazonAuthorisationToken = this.mwsToken;
    client.amazonSpApiToken = this.spApiCode;

    client.shopifyCode = this.shopifyCode;
    client.shopifyShop = this.shopifyShop;

    client.authorisationTarget = this.authorisationTarget;
    client.marketplace = this.marketplace;
    client.xeroCode = this.xeroCode;
    client.xeroCodeVerifier = this.xeroCodeVerifier;

    client.accountant = this.accountant;
    client.id = this.clientId;
    client.name = this.businessName;
    client.correspondenceLanguage = this.translateService.currentLang;

    this.clientService.connect(client)
      .pipe(first())
      .subscribe(
        data => {
          var result = data as RegistrationResponseResult;
          var registrationResponse = result.result;

          this.businessName = registrationResponse.businessName;

          this.requestProcessing = false;

          if (registrationResponse.errorMessage === 'Already registered')
          {
            this.requestSuccessful = true;
          }
          else if (registrationResponse.errorMessage === 'Updated successfully')
          {
            this.requestSuccessful = true;
            this.showDetails = true;
          }
          else if (registrationResponse.errorMessage === 'Registered successfully')
          {
            this.requestSuccessful = true;
            this.showDetails = true;
          }
          else if (registrationResponse.errorMessage === 'Amazon Seller account has been already authorised')
          {
            this.requestSuccessful = true;
            this.showDetails = true;
          }
          else
          {
            this.customErrorMessage = true;
            this.contactYourAccountantMessage = registrationResponse.errorMessage == null ? '' : registrationResponse.errorMessage;

            this.requestFailed = true;
            this.showDetails = true;

            this.showContactUsLink = true;
          }
        },
        error => {
          this.customErrorMessage = true;
          this.contactYourAccountantMessage = util.format(this.translateService.instant('failedToAcquireTokenMessage'), this.salesChannel);

          this.requestProcessing = false;
          this.requestFailed = true;

          this.showDetails = true;
        },() =>
        {
          this.requestProcessing = false;
        });
  }
}
