import { Injectable, Injector } from "@angular/core";
import { Overlay, OverlayConfig } from "@angular/cdk/overlay";
import { ComponentPortal, PortalInjector } from "@angular/cdk/portal";
import { InformationComponent } from "../information/information.component";
import { InformationOverlayRef } from "./information-overlay-ref";

interface InformationConfig {
  panelClass?: string;
  hasBackdrop?: boolean;
  backdropClass?: string;
}

const DEFAULT_CONFIG: InformationConfig = {
  hasBackdrop: true,
  backdropClass: 'dark-backdrop',
  panelClass: 'tm-file-preview-dialog-panel'
}

@Injectable()
export class InformationOverlayService {

  // Inject overlay service
  constructor(
    private injector: Injector,
    private overlay: Overlay) { }

  open(config: InformationConfig = {}) {

    const dialogConfig = { ...DEFAULT_CONFIG, ...config };

    // Returns an OverlayRef (which is a PortalHost)
    const overlayRef = this.createOverlay(dialogConfig);

    const dialogRef = new InformationOverlayRef(overlayRef);

    // Create ComponentPortal that can be attached to a PortalHost
    const informationPortal = new ComponentPortal(InformationComponent);

    overlayRef.backdropClick().subscribe(_ => dialogRef.close());

    // Attach ComponentPortal to PortalHost
    overlayRef.attach(informationPortal);

    return dialogRef;
  }

  private createOverlay(config: InformationConfig) {
    const overlayConfig = this.getOverlayConfig(config);
    return this.overlay.create(overlayConfig);
  }

  private getOverlayConfig(config: InformationConfig): OverlayConfig {
    const positionStrategy = this.overlay.position()
      .global()
      .centerHorizontally()
      .centerVertically();

    const overlayConfig = new OverlayConfig({
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass,
      panelClass: config.panelClass,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy
    });

    return overlayConfig;
  }

  private createInjector(dialogRef: InformationOverlayRef): PortalInjector {
    // Instantiate new WeakMap for our custom injection tokens
    const injectionTokens = new WeakMap();

    // Set custom injection tokens
    injectionTokens.set(InformationOverlayRef, dialogRef);

    // Instantiate new PortalInjector
    return new PortalInjector(this.injector, injectionTokens);
  }
}
