export const LANG_ZH_NAME = 'zh';

export const LANG_ZH_TRANS = {
  'https://sellercentral.amazon.co.uk/gp/mws/registration/register.html?signInPageDisplayed=1&devAuth=1&developerName=Vescati%20(Verili)&devMWSAccountId=3990-8830-8654': 'https://sellercentral.amazon.co.uk/gp/mws/registration/register.html?signInPageDisplayed=1&devAuth=1&developerName=Vescati%20(Verili)&devMWSAccountId=3990-8830-8654',
  'https://verilistorage2.blob.core.windows.net/userguide/AMAZON_Auth.png': 'https://verilistorage2.blob.core.windows.net/userguide/AMAZON_Auth-zh.png',
  'https://verilistorage2.blob.core.windows.net/userguide/AMAZON_ClickNext.png': 'https://verilistorage2.blob.core.windows.net/userguide/AMAZON_ClickNext-zh.png',
  'https://verilistorage2.blob.core.windows.net/userguide/AMAZON_Congrat.png': 'https://verilistorage2.blob.core.windows.net/userguide/AMAZON_Congrat-zh.png',
  'Username': '用戶名',
  'Password': '密码',
  'New password': '新密码',
  'Email or username': 'Email or username',
  'Please login using either your email or the username provided': 'Please login using either your email or the username provided',
  'Confirm new password': 'Confirm new password',
  'Passwords must match': 'Passwords must match',

  "connectingMessageWithSalesChannel": "连接中...",
  'processingMessageWithSalesChannel': '处理您的 <strong>%s</strong> 授权...',
  'completedMessageWithSalesChannel': '您的 <strong>%s</strong> 授权流程现已完成！',
  "connectedMessage": "您的账户已连接",
  'connectedMessageWithoutAccountancy': '您的商店已连接。请联系我们创建一个账户。',
  'Business name': '企业名称',
  'Accountancy': '会计服务',
  'Accountant': '会计师',
  'failedMessage': '<strong>%s</strong> 授权流程失败',
  'contactYourAccountantMessage': '请联系您的会计师 <strong>%s</strong> 在 <strong>%s</strong>.',
  'invalidTokenMessage': '(提供的令牌无效)',
  'failedToAcquireTokenMessage': '(无法获取%s令牌)',

  'Email': '电子邮件',
  'Company name': '公司名',
  'Amazon Seller Id / Merchant Token': '亚马逊卖家ID',
  'Register': '寄存器',
  'Login': '登录',
  'Registration': '注册',
  'Amazon Connection Registration': '亚马逊连接注册',
  'Please enter an email address': '请输入电子邮件地址',
  'Email address is not valid': '电子邮件地址无效',
  'Please enter the company name': '请输入公司名称',
  'Please enter an Amazon Seller Id': '请输入一个亚马逊卖家ID',
  'Already registered': '已经登记了',
  'Registered successfully': '注册成功',
  'Updated successfully': '令牌更新成功',
  'MWS authorisation token': 'MWS授权令牌',
  'Update': '更新',
  'connection failed': '连接失败',
  'Invalid Seller Id / MWS Token combination': '无效的卖方ID / MWS令牌组合',
  'DATA USAGE': '数据使用',
  'We take data usage and storage very seriously. We only access data from your Amazon account that is absolutely necessary in order to process your international VAT.': '我们非常重视数据使用和存储。 我们只访问您的亚马逊帐户中的数据，这是处理您的国际增值税所必需的',
  'Data we access:': '我们访问的数据：',
  'Amazon VAT Transactions Report': '亚马逊增值税交易报告',
  'This is the information necessary for us to compile your VAT liability and is just your sales data that you can freely download from Amazon in their report.': '这是我们编制增值税责任所需的信息，只是您可以在其报告中从亚马逊免费下载的销售数据。',
  'AMAZON CONNECTION': '亚马逊连接',
  'Sign in to Amazon to get the Seller Id and MWS Token': '登录到亚马逊以获得卖方ID和MWS令牌',
  'Step 1': '第1步',
  "By clicking on the 'Sign in to Amazon to get the Seller Id and MWS Token' button,": "通过单击“登录到亚马逊以获取卖方ID和MWS令牌”按钮，",
  "you can authorise us to download reports automatically directly from Amazon EU.": '您可以授权我们直接从Amazon EU自动下载报告',
  'Step 2': '第2步',
  'Please select the required checkbox as shown below to continue:': '请选中所需的复选框，如下所示以继续：',
  'Step 3': '第三步',
  'After the successful authorisation please copy the Seller Id and custom MWS Authorisation Token (highlighted below) and paste into the registration form.': '成功获得授权后，请复制卖方ID和自定义的MWS授权令牌（在下面突出显示）并粘贴到注册表单中',
};
