export const LANG_IT_NAME = 'it';

export const LANG_IT_TRANS = {
  'https://sellercentral.amazon.co.uk/gp/mws/registration/register.html?signInPageDisplayed=1&devAuth=1&developerName=Vescati%20(Verili)&devMWSAccountId=3990-8830-8654': 'https://sellercentral.amazon.it/gp/mws/registration/register.html?signInPageDisplayed=1&devAuth=1&developerName=Vescati%20(Verili)&devMWSAccountId=3990-8830-8654',
  'https://verilistorage2.blob.core.windows.net/userguide/AMAZON_Auth.png': 'https://verilistorage2.blob.core.windows.net/userguide/AMAZON_Auth-it.png',
  'https://verilistorage2.blob.core.windows.net/userguide/AMAZON_ClickNext.png': 'https://verilistorage2.blob.core.windows.net/userguide/AMAZON_ClickNext-it.png',
  'https://verilistorage2.blob.core.windows.net/userguide/AMAZON_Congrat.png': 'https://verilistorage2.blob.core.windows.net/userguide/AMAZON_Congrat-it.png',
  'Username': 'Nome utente',
  'Password': 'Password',
  'New password': 'Nuova password',
  'Email or username': 'E-mail o nome utente',
  'Please login using either your email or the username provided': 'Si prega di effettuare il login utilizzando la tua email o il nome utente fornito',
  'Confirm new password': 'Conferma nuova password',
  'Passwords must match': 'Le password devono corrispondere',

  "connectingMessageWithSalesChannel": "Connessione in corso...",
  'processingMessageWithSalesChannel': 'Elaborazione della tua <strong>%s</strong> autorizzazione...',
  'completedMessageWithSalesChannel': 'Il tuo processo di autorizzazione <strong>%s</strong> è ora completo!',
  "connectedMessage": "Il tuo account è connesso",
  'connectedMessageWithoutAccountancy': 'Il tuo negozio è connesso. Per favore contattaci per creare un account.',
  'Business name': 'Nome attività',
  'Accountancy': 'Contabilità',
  'Accountant': 'Commercialista',
  'failedMessage': 'Il processo di autorizzazione <strong>%s</strong> non è riuscito',
  'contactYourAccountantMessage': 'Si prega di contattare il tuo commercialista <strong>%s</strong> a <strong>%s</strong>.',
  'invalidTokenMessage': '(Il token fornito non è valido)',
  'failedToAcquireTokenMessage': '(Impossibile acquisire il token %s)',

  'Email': 'E-mail',
  'Company name': "Nome dell'azienda",
  'Amazon Seller Id / Merchant Token': 'ID venditore Amazon',
  'Register': 'Registro',
  'Login': 'Login',
  'Registration': 'Registrazione',
  'Amazon Connection Registration': 'Registrazione della connessione Amazon',
  'Please enter an email address': 'Inserisci un indirizzo email',
  'Email address is not valid': "L'indirizzo e-mail non è valido",
  'Please enter the company name': 'Inserisci il nome della tua azienda',
  'Please enter an Amazon Seller Id': 'Inserisci il tuo ID venditore Amazon',
  'Already registered': 'Già registrato',
  'Registered successfully': 'Registrato con successo',
  'Updated successfully': 'Aggiornato correttamente',
  'MWS authorisation token': 'Token di autorizzazione MWS',
  'Update': 'Aggiornamento',
  'connection failed': 'connessione non riuscita',
  'Invalid Seller Id / MWS Token combination': 'Combinazione di ID venditore/ Token MWS non valida',
  'DATA USAGE': 'UTILIZZO DEI DATI',
  'We take data usage and storage very seriously. We only access data from your Amazon account that is absolutely necessary in order to process your international VAT.': "Prendiamo molto sul serio l'utilizzo e l'archiviazione dei dati. Accediamo solo ai dati dal tuo account Amazon che sono assolutamente necessari per elaborare la tua IVA internazionale.",
  'Data we access:': 'Dati a cui si accede:',
  'Amazon VAT Transactions Report': 'Report transazioni IVA Amazon',
  'This is the information necessary for us to compile your VAT liability and is just your sales data that you can freely download from Amazon in their report.': 'Queste sono le informazioni necessarie per compilare la tua responsabilità IVA ed è solo i tuoi dati di vendita che puoi scaricare liberamente da Amazon nel loro rapporto.',
  'AMAZON CONNECTION': 'CONNESSIONE AMAZON',
  'Sign in to Amazon to get the Seller Id and MWS Token': "Accedi ad Amazon per ottenere l'ID venditore e il token MWS",
  'Step 1': 'Fase  1',
  "By clicking on the 'Sign in to Amazon to get the Seller Id and MWS Token' button,": "Cliccando sul pulsante 'Accedi ad Amazon per ottenere l'ID venditore e il token MWS',",
  "you can authorise us to download reports automatically directly from Amazon EU.": 'puoi autorizzarci a scaricare automaticamente i rapporti direttamente da Amazon EU.',
  'Step 2': 'Fase  2',
  'Please select the required checkbox as shown below to continue:': 'Si prega di selezionare le caselle di controllo necessarie come mostrato di seguito per continuare:',
  'Step 3': 'Fase 3',
  'After the successful authorisation please copy the Seller Id and custom MWS Authorisation Token (highlighted below) and paste into the registration form.': "Dopo la corretta autorizzazione, copia l'ID venditore e il token di autorizzazione MWS personalizzato(evidenziato di seguito) e incollalo nel modulo di registrazione.",
};
