export const LANG_EN_NAME = 'en';

export const LANG_EN_TRANS = {
  'hello world': 'hello world',

  'connectingMessageWithSalesChannel': 'Connecting...',
  'processingMessageWithSalesChannel': 'Processing your <strong>%s</strong> authorisation...',
  'completedMessageWithSalesChannel': 'Your <strong>%s</strong> authorisation process is now complete!',
  'connectedMessage': 'Your account is connected',
  'connectedMessageWithoutAccountancy': 'Your shop is connected. Please contact us to create an account.',
  'Business name': 'Business name',
  'Accountancy': 'Accountancy',
  'Accountant': 'Accountant',
  'failedMessage': 'Please contact your Account Manager',
  'contactYourAccountantMessage': 'Please contact your accountant <strong>%s</strong> at <strong>%s</strong>.',
  'invalidTokenMessage': '(Authorisation link will be provided)',
  'failedToAcquireTokenMessage': '(Failed to acquire %s token)',
};
