import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Client } from '../_models/client';

@Injectable()
export class ClientService {
  private baseUrl: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  register(client: Client) {
    return this.http.put(`${this.baseUrl}/clients/register`, client);
  }

  connect(client: Client) {
    return this.http.put(`${this.baseUrl}/clients/connect`, client);
  }

  getByUserId(username: string) {
    return this.http.get(`${this.baseUrl}/clients/byUsername/${username}`);
  }

  getById(username: string) {
    return this.http.get(`${this.baseUrl}/clients/${username}`);
  }

  isAmazonTokenValid(client: Client) {
    return this.http.post(`${this.baseUrl}/clients/isAmazonTokenValid`, client);
  }

  getCompanyName(sellerId: string) {
    return this.http.get(`${this.baseUrl}/clients/getCompanyName/${sellerId}`);
  }

  getAmazonRegistration(parameters: string) {
    return this.http.get(`${this.baseUrl}/clients/getAmazonRegistration/${parameters}`);
  }

  getXeroRegistration(parameters: string) {
    return this.http.get(`${this.baseUrl}/clients/getXeroRegistration/${parameters}`);
  }

  getShopifyRegistration(parameters: string) {
    return this.http.get(`${this.baseUrl}/clients/getShopifyRegistration/${parameters}`);
  }

  getShopifyAppConnection(params: { [key: string]: string }): Observable<any> {
    const queryString = new URLSearchParams(params).toString();
    const url = `${this.baseUrl}/clients/getShopifyAppConnection?${queryString}`;
    return this.http.get(url);  }
}
