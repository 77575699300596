export class Client {
  name: string;
  email: string;

  authorisationTarget: string;
  marketplace: string;

  amazonSellerId: string;
  amazonAuthorisationToken: string;
  amazonSpApiToken: string;

  xeroCode: string;
  xeroCodeVerifier: string;

  shopifyCode: string;
  shopifyShop: string;

  accountant: string;
  id: string;

  password: string;
  isUpdate: boolean;
  correspondenceLanguage: string;
}
