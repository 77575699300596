import { Component, OnInit, Inject, OnDestroy  } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, AsyncValidatorFn, AbstractControl } from '@angular/forms';
import { first, map, catchError } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

import { AlertService } from '../_services/alert.service';
import { ClientService } from '../_services/client.service';
import { TranslateService } from '../_translations/translate.service';
import { UserData } from '../_providers/userData';
import { MessageResult } from '../_models/messageResult';
import { ClientResult } from '../_models/clientResult';
import { Client } from '../_models/client';
import { AmazonConnectionResult } from '../_models/amazonConnectionResult';
import { Observable } from 'rxjs';
import { InformationOverlayService } from '../_services/information-overlay.service';
import { InformationOverlayRef } from '../_services/information-overlay-ref';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy  {
  public baseUrl: string;
  registrationForm: FormGroup;
  passwordGroup: FormGroup;
  amazonGroup: FormGroup;
  submitted = false;
  newClient = false;
  accountant: string;
  language: string;
  private sub: any;

  constructor(
    private route: ActivatedRoute,
    private alertService: AlertService,
    private clientService: ClientService,
    private translateService: TranslateService,
    private formBuilder: FormBuilder,
    public userData: UserData,
    private informationOverlayService: InformationOverlayService,
    @Inject('BASE_URL') baseUrl: string) {
    this.baseUrl = baseUrl;

    this.passwordGroup = this.formBuilder.group({
      password: [''],
      confirmPassword: [''],
    }, { validator: this.checkPasswords });

    this.amazonGroup = this.formBuilder.group({
      amazonSellerId: ['', new FormControl('', Validators.required)],
      amazonAuthorisationToken: ['', new FormControl('', Validators.required)],
    }, { validator: this.checkAmazonConnection.bind(this) });

    this.registrationForm = this.formBuilder.group({
      email: new FormControl('', [Validators.required, Validators.email]),
      name: new FormControl('', Validators.required),
      passwordGroup: this.passwordGroup,
      amazonGroup: this.amazonGroup,
    });

    clientService.getById(userData.user.username)
      .pipe(first())
      .subscribe(
        data => {
          var result = data as ClientResult;
          var client = result.result;
          if (client !== null) {

            this.f.email.setValue(client.email);
            this.f.name.setValue(client.name);
            this.a.amazonSellerId.setValue(client.amazonSellerId);
            this.a.amazonAuthorisationToken.setValue(client.amazonAuthorisationToken);
            this.f.email.disable();
          }
          else {
            this.newClient = true;
          }
        },
        error => {
          this.alertService.error(this.translateService.instant("Unexpected error occurred"));
        });
  }

  get f() { return this.registrationForm.controls; }
  get p() { return this.passwordGroup.controls; }
  get a() { return this.amazonGroup.controls; }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.accountant = params['username'];
      this.language = params['language'];

      if (this.language == null) {
        this.language = 'en';
      }

      this.translateService.use(this.language);
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
  
  getButtonText() {
    return this.newClient
      ? this.translateService.instant('Register')
      : this.translateService.instant('Update');
  }

  getEmailErrorMessage() {
    return this.f.email.hasError('required') ? this.translateService.instant('Please enter an email address') :
      this.f.email.hasError('email') ? this.translateService.instant('Email address is not valid') :
        '';
  }

  getSellerIdErrorMessage() {
    return this.a.amazonSellerId.hasError('required') ? this.translateService.instant('Please enter an Amazon Seller Id') : '';
  }

  getPasswordMatchMessage() {
    return this.translateService.instant('Passwords must match');
  }

  showHelp() {
    let dialogRef: InformationOverlayRef = this.informationOverlayService.open();
  }

  checkPasswords(group: FormGroup) {
    let password = group.controls.password.value;
    let confirmPassword = group.controls.confirmPassword.value;

    if (confirmPassword.length <= 0 && password.length <= 0) {
      return null;
    }

    if (confirmPassword !== password) {
      return {
        passwordsAreDifferent: true
      };
    }

    return null;
  }

  checkAmazonConnection(group: FormGroup) {
    let sellerId = group.controls.amazonSellerId.value;
    let token = group.controls.amazonAuthorisationToken.value;

    if (sellerId.length <= 0) {
      return null;
    }

    if (this.f.name.value <= 0) {
      this.clientService.getCompanyName(sellerId)
        .subscribe(
          data => {
            var result = data as MessageResult;
            this.f.name.setValue(result.result);
          });
    }

    if (token.length <= 0) {
      return null;
    }

    let client = new Client();
    client.email = this.f.email.value;
    client.amazonSellerId = sellerId;
    client.amazonAuthorisationToken = token;

    group.setErrors({ "dontUpdate": true });

    return this.clientService.isAmazonTokenValid(client)
      .subscribe(
      data => {
        var result = data as AmazonConnectionResult;
        if (result.isAmazonTokenValid) {
          group.setErrors(null);
          return null;
        }
        else {

          group.setErrors({ "connectionFailed": true });
          return { connectionFailed: true }
        }
        },
      error => {
        this.alertService.error(this.translateService.instant('connection failed'));
      });
  }

  onSubmit() {
    this.submitted = true;

    if (this.registrationForm.invalid) {
      this.submitted = false;
      return;
    }

    var client = this.registrationForm.value;
    client.password = this.p.password.value;
    client.isUpdate = !this.newClient;
    client.email = this.f.email.value;
    client.amazonSellerId = this.a.amazonSellerId.value;
    client.amazonAuthorisationToken = this.a.amazonAuthorisationToken.value;
    client.accountant = this.accountant;
    client.correspondenceLanguage = this.translateService.currentLang;

    this.clientService.register(client)
      .pipe(first())
      .subscribe(
        data => {
          var result = data as MessageResult;
          if (result.result === "already registered") {
            this.alertService.error(this.translateService.instant(result.result), false);
          }
          else {
            this.alertService.success(this.translateService.instant(result.result), false);
          }

          this.submitted = false;
        },
      error => {
          this.alertService.error(this.translateService.instant(error));
          this.submitted = false;
        });
  }
}
