export const LANG_ES_NAME = 'es';

export const LANG_ES_TRANS = {
  'https://sellercentral.amazon.co.uk/gp/mws/registration/register.html?signInPageDisplayed=1&devAuth=1&developerName=Vescati%20(Verili)&devMWSAccountId=3990-8830-8654': 'https://sellercentral.amazon.es/gp/mws/registration/register.html?signInPageDisplayed=1&devAuth=1&developerName=Vescati%20(Verili)&devMWSAccountId=3990-8830-8654',
  'https://verilistorage2.blob.core.windows.net/userguide/AMAZON_Auth.png': 'https://verilistorage2.blob.core.windows.net/userguide/AMAZON_Auth-es.png',
  'https://verilistorage2.blob.core.windows.net/userguide/AMAZON_ClickNext.png': 'https://verilistorage2.blob.core.windows.net/userguide/AMAZON_ClickNext-es.png',
  'https://verilistorage2.blob.core.windows.net/userguide/AMAZON_Congrat.png': 'https://verilistorage2.blob.core.windows.net/userguide/AMAZON_Congrat-es.png',
  'Username': 'Nombre de usuario',
  'Password': 'Contraseña',
  'New password': 'Nueva contraseña',
  'Email or username': 'Email o nombre de usuario',
  'Please login using either your email or the username provided': 'Inicie sesión utilizando su correo electrónico o el nombre de usuario proporcionado',
  'Confirm new password': 'Confirmar nueva contraseña',
  'Passwords must match': 'Las contraseñas deben coincidir',

  "connectingMessageWithSalesChannel": "Conectando...",
  'processingMessageWithSalesChannel': 'Procesando tu <strong>%s</strong> autorización...',
  'completedMessageWithSalesChannel': '¡Tu proceso de autorización <strong>%s</strong> está completo ahora!',
  "connectedMessage": "Tu cuenta está conectada",
  'connectedMessageWithoutAccountancy': 'Tu tienda está conectada. Por favor, contáctanos para crear una cuenta.',
  'Business name': 'Nombre de empresa',
  'Accountancy': 'Contabilidad',
  'Accountant': 'Contador',
  'failedMessage': 'El proceso de autorización <strong>%s</strong> ha fallado',
  'contactYourAccountantMessage': 'Por favor, contacta a tu contador <strong>%s</strong> en <strong>%s</strong>.',
  'invalidTokenMessage': '(El token proporcionado no es válido)',
  'failedToAcquireTokenMessage': '(No se pudo adquirir el token %s)',

  'Email': 'Email',
  'Company name': 'Nombre de empresa',
  'Amazon Seller Id / Merchant Token': 'ID del vendedor de Amazon',
  'Register': 'Registrarse',
  'Login': 'Iniciar sesión',
  'Registration': 'Registro',
  'Amazon Connection Registration': 'Registro de Amazon Conexión',
  'Please enter an email address': 'Por favor introduzca una dirección de correo eléctronico',
  'Email address is not valid': 'La dirección de correo electrónico no es válida',
  'Please enter the company name': 'Por favor ingrese el nombre de su empresa',
  'Please enter an Amazon Seller Id': 'Ingrese su ID del vendedor de Amazon',
  'Already registered': 'Ya esta registrado',
  'Registered successfully': 'Registrado correctamente',
  'Updated successfully': 'Actualizada con éxito',
  'MWS authorisation token': 'Token de autorización MWS',
  'Update': 'Actualizar',
  'connection failed': 'la conexión falló',
  'Invalid Seller Id / MWS Token combination': 'ID del vendedor  / token MWS combinación no válido',
  'DATA USAGE': 'USO DE DATOS',
  'We take data usage and storage very seriously. We only access data from your Amazon account that is absolutely necessary in order to process your international VAT.': 'Nos tomamos muy en serio el uso y el almacenamiento de datos. Solo accedemos a los datos de su cuenta de Amazon que son absolutamente necesarios para procesar su IVA internacional..',
  'Data we access:': 'Los datos a los que accedemos:',
  'Amazon VAT Transactions Report': 'Informe de transacciones de IVA de Amazon',
  'This is the information necessary for us to compile your VAT liability and is just your sales data that you can freely download from Amazon in their report.': 'Esta es la información necesaria para que podamos recopilar su responsabilidad de IVA y son solo sus datos de ventas que puede descargar libremente de Amazon en su informe.',
  'AMAZON CONNECTION': 'AMAZON CONEXIÓN',
  'Sign in to Amazon to get the Seller Id and MWS Token': 'Inicie sesión en Amazon para obtener el ID del vendedor y el token MWS',
  'Step 1': 'Paso 1',
  "By clicking on the 'Sign in to Amazon to get the Seller Id and MWS Token' button,": "Al hacer clic en el botón 'Iniciar sesión en Amazon para obtener el ID del vendedor y el token MWS',",
  "you can authorise us to download reports automatically directly from Amazon EU.": 'puede autorizarnos a descargar informes automáticamente directamente desde Amazon EU.',
  'Step 2': 'Paso 2',
  'Please select the required checkbox as shown below to continue:': 'Seleccione las casillas de verificación requeridas como se muestra a continuación para continuar:',
  'Step 3': 'Paso 3',
  'After the successful authorisation please copy the Seller Id and custom MWS Authorisation Token (highlighted below) and paste into the registration form.': 'Después de la autorización exitosa, copie el ID del vendedor y el token de autorización MWS personalizado (resaltado a continuación) y péguelo en el formulario de registro.',
};
