<mat-toolbar [style.background-color]="toolbarColor" style="height: 96px; box-shadow: 0 0 40px 0 rgb(0 0 0 / 11%); position: relative; z-index: 2;">
    <mat-toolbar-row style="margin: auto">
        <div class="@logo" fxLayoutAlign="left center" style="margin-left: 20px;">
            <span [innerHTML]="logo"></span>
        </div>
        <span class="fill-remaining-space"></span>
        <!--<div fxShow="true" style="font-size: 0.8em">
          <select (change)="selectLang($event.target.value)" [value]="currentLang()">
            <option value="de">Deutsch</option>
            <option value="en">English</option>
            <option value="es">Española</option>
            <option value="fr">Française</option>
            <option value="it">Italiana</option>
            <option value="zh">中文</option>
          </select>
        </div>
        <button mat-icon-button (click)="showPreview()">
          <mat-icon mat-list-icon>help_outline</mat-icon>
        </button>-->
    </mat-toolbar-row>
</mat-toolbar>

<mat-sidenav-container [style.background-color]="backgroundColor" class="example-container" style="height: calc(100vh - 96px); overflow-y: auto;">
    <mat-sidenav-content>
        <alert></alert>
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>
