import { Injectable } from '@angular/core';
import { User } from '../_models/user';
import { Agent } from '../_models/agent';

@Injectable()
export class UserData {

  public user: User;
  public agent: Agent;

  public constructor() { }

}
