import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '../app/_translations/translate.service';
import { LanguageService } from "./_services/languag.service";
import { OverlayModule } from '@angular/cdk/overlay';
import { AuthenticationService } from '../app/_services/authentication.service';
import { User } from '../app/_models/user';
import { UserData } from '../app/_providers/userData';
import { InformationOverlayService } from './_services/information-overlay.service';
import { InformationOverlayRef } from './_services/information-overlay-ref';
import { WhitelabelService } from "./_services/whitelabel.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  public supportedLanguages: any[];
  loading = false;

  toolbarColor = '';
  logo = '';
  backgroundColor = '';
  private whitelabelSub: any;

  constructor(
    private authenticationService: AuthenticationService,
    private translationService: TranslateService,
    private languageService: LanguageService,
    private whitelabelService: WhitelabelService,
    private informationOverlayService: InformationOverlayService,
    public userData: UserData) {

    this.whitelabelSub = this.whitelabelService.layoutChanged$.subscribe(language => { this.updateLayout(); });

    this.logout();
  }

  showPreview() {
    let dialogRef: InformationOverlayRef = this.informationOverlayService.open();

    //setTimeout(() => {
    //  dialogRef.close();
    //}, 2000);
  }

  ngOnInit() {
    this.supportedLanguages = [
      { display: 'English', value: 'en' },
      { display: 'Española', value: 'es' },
      { display: 'Italiana', value: 'it' },
      { display: 'Deutsch', value: 'de' },
      { display: '中文', value: 'zh' },
    ];

    var lang = window.navigator.language;

    this.selectLang(lang.split('-')[0]);

    this.updateLayout();
  }

  onLoad(event: Event) {
    this.loading = false;
  }

  currentLang() {
    return this.translationService.currentLang;
  }

  isCurrentLang(lang: string) {
    // check if the selected lang is current lang
    return lang === this.translationService.currentLang;
  }

  updateLayout() {
    this.logo = this.whitelabelService.logo;
    this.toolbarColor = this.whitelabelService.toolbarColor;
    this.backgroundColor = this.whitelabelService.backgroundColor;
}

  selectLang(lang: string) {
    // set current lang;
    this.translationService.use(lang);
    this.languageService.changeLanguage(lang);
  }

  public isAuthenticated(): boolean {
    return this.authenticationService.isAuthenticated();
  }

  public getUser(): User {
    return this.userData.user;
  }

  public logout(): void {
    this.authenticationService.logout();
  }
}
