<div layout="column" layout-align="center center" layout-fill="layout-fill" style="height: 100vh;">

  <div class="container" fxLayoutAlign="center center" style="display: inline-flex; align-items: center; min-height: calc(100vh - 96px); flex-direction: row">
      <div class="text-center" style="font-size: 2em; color: #083d5a; margin-bottom: 3em">
          <div>
              <img src="{{ salesChannelLogo }}" height="{{ logoHeight }}" width="{{ logoWidth }}" align="bottom" style="margin-bottom: 3em; margin-top: 1em; object-fit: contain;" />
          </div>
          <div *ngIf="requestProcessing" [ngStyle]="{'color': fontColor}" style="font-weight: 300">
              <div style="display: inline-flex; line-height: 32px">
                  <mat-progress-spinner mode="indeterminate" diameter="32" style="margin-right: 1em;"></mat-progress-spinner>
                  <span [innerHTML]="processingMessage"></span>
              </div>
          </div>
          <div *ngIf="requestSuccessful" [ngStyle]="{'color': fontColor}" style="font-weight: 300">
              <span [innerHTML]="completedMessage"></span>
              <br/>
              <div *ngIf="showDetails" style="margin-left: 6.25em; font-size: 20px">
                  <div *ngIf="showBusinessName" style="text-align: left">
                      {{ 'Business name' | translate }}: <strong>{{ businessName }}</strong>
                  </div>
                  <div style="text-align: left">
                      {{ 'Accountancy' | translate }}: <strong>{{ accountancyName }}</strong>
                  </div>
                  <div style="text-align: left">
                      {{ 'Accountant' | translate }}: <strong>{{ accountantName }}</strong>
                  </div>
              </div>
          </div>
          <div *ngIf="requestFailed">
              <span style="color: #800000" [innerHTML]="failedMessage"></span>
              <br/>
              <div *ngIf="showDetails" style="font-size: 18px">
                  <span [innerHTML]="contactYourAccountantMessage"></span>
              </div>
          </div>
          <div *ngIf="showContactUsLink">
              <a [href]="contactUsLink" target="_blank"
                 style="color: initial; text-decoration: underline; font-size: initial; font-weight: normal;">contact us</a>
          </div>
      </div>
  </div>
</div>
