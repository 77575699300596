import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { User } from '../_models/user';
import { UserData } from '../_providers/userData';

@Injectable()
export class AuthenticationService {
  private baseUrl: string;
  private isLoggedIn = false;
  private data: UserData;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, public router: Router, private userData: UserData) {
    this.baseUrl = baseUrl;
    this.data = userData;
  }

    login(username: string, password: string) {
      return this.http.post<any>(`${this.baseUrl}/users/authenticate`, { username: username, password: password })
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                if (user && user.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                  localStorage.setItem('currentUser', JSON.stringify(user));
                  this.isLoggedIn = true;
                }

              this.data.user = user;
                return user;
        }, error => {
          this.isLoggedIn = false;
          return null;
        }));
    }

  setLoggedIn(loggedIn: boolean) {
    this.isLoggedIn = loggedIn;
  }

    logout() {
        // remove user from local storage to log user out
      localStorage.removeItem('currentUser');
      this.data.user = null;
      this.isLoggedIn = false;
      //this.router.navigate(['/login']);
    }

  public isAuthenticated(): boolean {
    return this.isLoggedIn;
  }

}
