export const LANG_FR_NAME = 'fr';

export const LANG_FR_TRANS = {
  'https://sellercentral.amazon.co.uk/gp/mws/registration/register.html?signInPageDisplayed=1&devAuth=1&developerName=Vescati%20(Verili)&devMWSAccountId=3990-8830-8654': 'https://sellercentral.amazon.fr/gp/mws/registration/register.html?signInPageDisplayed=1&devAuth=1&developerName=Vescati%20(Verili)&devMWSAccountId=3990-8830-8654',
  'https://verilistorage2.blob.core.windows.net/userguide/AMAZON_Auth.png': 'https://verilistorage2.blob.core.windows.net/userguide/AMAZON_Auth-fr.png',
  'https://verilistorage2.blob.core.windows.net/userguide/AMAZON_ClickNext.png': 'https://verilistorage2.blob.core.windows.net/userguide/AMAZON_ClickNext-fr.png',
  'https://verilistorage2.blob.core.windows.net/userguide/AMAZON_Congrat.png': 'https://verilistorage2.blob.core.windows.net/userguide/AMAZON_Congrat-fr.png',
  'Username': "Nom d'utilisateur",
  'Password': 'Mot de passe',
  'New password': 'Nouveau mot de passe',
  'Email or username': "E-mail ou nom d'utilisateur",
  'Please login using either your email or the username provided': 'Veuillez vous connecter à l’aide de votre e-mail ou du nom d’utilisateur fourni',
  'Confirm new password': 'Confirmer un nouveau mot de passe',
  'Passwords must match': 'Les mots de passe doivent correspondre',

  "connectingMessageWithSalesChannel": "Connexion en cours...",
  'processingMessageWithSalesChannel': 'Traitement de votre <strong>%s</strong> autorisation...',
  'completedMessageWithSalesChannel': 'Votre processus d\'autorisation <strong>%s</strong> est maintenant terminé!',
  "connectedMessage": "Votre compte est connecté",
  'connectedMessageWithoutAccountancy': 'Votre boutique est connectée. Veuillez nous contacter pour créer un compte.',
  'Business name': 'Nom d\'entreprise',
  'Accountancy': 'Comptabilité',
  'Accountant': 'Comptable',
  'failedMessage': 'Le processus d\'autorisation <strong>%s</strong> a échoué',
  'contactYourAccountantMessage': 'Veuillez contacter votre comptable <strong>%s</strong> à <strong>%s</strong>.',
  'invalidTokenMessage': '(Le jeton fourni n’est pas valide)',
  'failedToAcquireTokenMessage': '(Impossible d’acquérir le jeton % s)',

  'Email': 'E-mail',
  'Company name': 'Nom de l’entreprise',
  'Amazon Seller Id / Merchant Token': 'Id du vendeur Amazon',
  'Register': 'Registre',
  'Login': 'Connectez-vous',
  'Registration': 'Enregistrement',
  'Amazon Connection Registration': 'Enregistrement de connexion Amazon',
  'Please enter an email address': 'Veuillez entrer une adresse e-mail',
  'Email address is not valid': 'L’adresse e-mail n’est pas valide',
  'Please enter the company name': 'Veuillez entrer le nom de votre entreprise',
  'Please enter an Amazon Seller Id': 'Veuillez entrer votre Identifiant vendeur Amazon',
  'Already registered': 'Déjà enregistré',
  'Registered successfully': 'Enregistré avec succès',
  'Updated successfully': 'Mis à jour avec succès',
  'MWS authorisation token': 'Jeton d’autorisation MWS',
  'Update': 'Mettre à jour',
  'connection failed': 'échec de la connexion',
  'Invalid Seller Id / MWS Token combination': 'Combinaison id /MWS de jetons de vendeur non valide',
  'DATA USAGE': 'UTILISATION DES DONNÉES',
  'We take data usage and storage very seriously. We only access data from your Amazon account that is absolutely necessary in order to process your international VAT.': 'Nous prenons l’utilisation et le stockage des données très au sérieux. Nous n’accédons qu’aux données de votre compte Amazon qui sont absolument nécessaires pour traiter votre TVA internationale.',
  'Data we access:': 'Données aux auxquel nous accédons :',
  'Amazon VAT Transactions Report': 'Rapport sur les transactions de TVA Amazon',
  'This is the information necessary for us to compile your VAT liability and is just your sales data that you can freely download from Amazon in their report.': 'C’est l’information nécessaire pour nous de compiler votre responsabilité TVA et est juste vos données de vente que vous pouvez télécharger librement à partir d’Amazon dans leur rapport.',
  'AMAZON CONNECTION': 'CONNEXION AMAZON',
  'Sign in to Amazon to get the Seller Id and MWS Token': 'Connectez-vous à Amazon pour obtenir l’id vendeur et le jeton MWS',
  'Step 1': 'Étape 1',
  "By clicking on the 'Sign in to Amazon to get the Seller Id and MWS Token' button,": "En cliquant sur le bouton « e connecter à Amazon pour obtenir l’id du vendeur et le jeton DE », ",
  "you can authorise us to download reports automatically directly from Amazon EU.": 'vous pouvez nous autoriser à télécharger des rapports automatiquement à partir d’Amazon EU.',
  'Step 2': 'Étape 2',
  'Please select the required checkbox as shown below to continue:': 'Veuillez sélectionner les cases à cocher requises comme indiqué ci-dessous pour continuer :',
  'Step 3': 'Étape 3',
  'After the successful authorisation please copy the Seller Id and custom MWS Authorisation Token (highlighted below) and paste into the registration form.': 'Après l’autorisation réussie, veuillez copier l’id du vendeur et le jeton d’autorisation MWS personnalisé (mis en évidence ci-dessous) et coller dans le formulaire d’enregistrement.',
};
