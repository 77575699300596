import { Component, AfterViewInit, OnInit, ViewChild } from '@angular/core';
import { trigger, state, transition, animate, style } from '@angular/animations';
import { MatStepper } from '@angular/material';

const ANIMATION_TIMINGS = '400ms cubic-bezier(0.25, 0.8, 0.25, 1)';

@Component({
  templateUrl: 'information.component.html',
  styleUrls: [ 'information.component.css' ],
  animations: [
    trigger('fade', [
      state('fadeOut', style({ opacity: 0 })),
      state('fadeIn', style({ opacity: 1 })),
      transition('* => fadeIn', animate(ANIMATION_TIMINGS))
    ]),
    trigger('slideContent', [
      state('void', style({ transform: 'translate3d(0, 25%, 0) scale(0.9)', opacity: 0 })),
      state('enter', style({ transform: 'none', opacity: 1 })),
      state('leave', style({ transform: 'translate3d(0, 25%, 0)', opacity: 0 })),
      transition('* => *', animate(ANIMATION_TIMINGS)),
    ]),
    trigger('slideText', [
      transition(":enter", [
        animate(0, style({ opacity: 1 }))
      ]),
      transition(":leave", [
        animate(0, style({ opacity: 0 }))
      ]),
      transition('* => *', animate(ANIMATION_TIMINGS)),
    ])
  ]
})

export class InformationComponent implements OnInit {
  loading = true;
  animationState: 'void' | 'enter' | 'leave' = 'enter';
  textState: 'void' | 'dataUsage' | 'amazon' = 'void';
  title: string;
  @ViewChild('stepper') stepper: MatStepper;

  ngOnInit() {
    this.loading = false;

    this.stepper.selectedIndex = 1;
  }

  onLoad(event: Event) {
    this.loading = false;
  }
}
