import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

//import { SharedModule } from './shared/shared.module';

import { CustomMaterialModule } from './material.module';
import { LoginComponent } from './login/login.component';
import { AlertComponent } from './_directives/alert.component';
import { AuthGuard } from './_guards/auth.guard';
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { AlertService } from './_services/alert.service';
import { AuthenticationService } from './_services/authentication.service';
import { UserService } from './_services/user.service';
import { ClientService } from './_services/client.service';
import { InformationOverlayService } from './_services/information-overlay.service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { ApplicationComponent } from './application/application.component';
import { ConnectComponent } from './connect/connect.component';
import { HomeComponent } from './home/home.component';
import { HelpComponent } from './help/help.component';
import { InformationComponent } from './information/information.component';
import { UserData } from "./_providers/userData";
import { TranslatePipe } from './_translations/translate.pipe';
import { TRANSLATION_PROVIDERS } from './_translations/translations';
import { TranslateService } from './_translations/translate.service';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AlertComponent,
    ApplicationComponent,
    ConnectComponent,
    HomeComponent,
    InformationComponent,
    HelpComponent,
    TranslatePipe,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    CustomMaterialModule,
    FormsModule,
    HttpClientModule,
    HttpModule,
    ReactiveFormsModule,
    //SharedModule,
    AppRoutingModule,
  ],
  providers: [
    AuthGuard,
    AlertService,
    AuthenticationService,
    UserService,
    ClientService,
    UserData,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    TRANSLATION_PROVIDERS,
    TranslateService,
    InformationOverlayService,
  ],
  entryComponents: [
    InformationComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
