import { Component, OnInit, Inject, OnDestroy  } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, AsyncValidatorFn, AbstractControl } from '@angular/forms';
import { first, map, catchError, retry } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

import { ClientService } from '../_services/client.service';
import { TranslateService } from '../_translations/translate.service';
import { LanguageService } from "../_services/languag.service";
import { UserData } from '../_providers/userData';
import { Contact } from '../_models/contact';
import { Client } from '../_models/client';
import { AmazonConnectionResult } from '../_models/amazonConnectionResult';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { InformationOverlayService } from '../_services/information-overlay.service';
import { ShopifyAppConnectionResponse } from "../_models/shopifyAppConnectionResponse";
import * as util from 'util';
import { WhitelabelService } from "../_services/whitelabel.service";
import { forEach } from '@angular/router/src/utils/collection';

@Component({
  selector: 'app-home',
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.css']
})
export class ApplicationComponent implements OnInit, OnDestroy  {
  public baseUrl: string;
  registrationForm: FormGroup;
  amazonGroup: FormGroup;
  submitGroup: FormGroup;
  submitted = false;
  canSubmit = false;
  completed: boolean;
  newClient = true;
  accountant: string;
  language: string;
  companyInvalid: boolean;
  spApiCode: string;
  shopifyCode: string;
  shopifyShop: string;
  xeroCode: string;
  xeroCodeVerifier: string;

  private sub: any;
  private languageSub: any;
  private whitelabelSub: any;

  requestProcessing = true;
  requestSuccessful = false;
  requestFailed = false;
  showDetails = false;
  showContactUsLink = false;

  customErrorMessage = false;

  authorisationTarget = '';
  marketplace = '';

  salesChannel = "";
  salesChannelLogo = "";
  logoHeight = 50.0;
  logoWidth = 166.0;

  fontFamily = "Arial";
  fontColor = "#083d5a";
  highlightColor = "title";
  toolbarColor = "title";
  contactUsLink = "";

  clientId = "";
  company = "";

  accountantName = "";
  accountantEmail = "";
  businessName = "";

  connectedToShopify = false;
  connectedToXero = false;
  redirectUrl = "";

  processingMessage = "";
  completedMessage = "";
  failedMessage = "";
  contactYourAccountantMessage = "";

  constructor(
    private route: ActivatedRoute,
    private clientService: ClientService,
    private translateService: TranslateService,
    private languageService: LanguageService,
    private whitelabelService: WhitelabelService,
    private formBuilder: FormBuilder,
    public userData: UserData,
    private informationOverlayService: InformationOverlayService,
    @Inject('BASE_URL') baseUrl: string) {
    this.baseUrl = baseUrl;

    this.whitelabelSub = this.whitelabelService.layoutChanged$.subscribe(language => { this.updateLayout(); });

    this.route.params.subscribe(params => {

      var urlParts = params['username'].toLowerCase().split('?');

      var authorisationTargetParts = urlParts[0].split('-');
      this.authorisationTarget = authorisationTargetParts[0];

      if (authorisationTargetParts.length > 1) {
        this.marketplace = authorisationTargetParts[1];
      }
      else {
        this.marketplace = null;
      }

      if (this.authorisationTarget.startsWith('xero')) {
        this.authorisationTarget = "xero";
        this.salesChannel = "Xero";
        this.salesChannelLogo = "assets/Xero_logo_black.svg";
        this.logoHeight = 120;
        this.logoWidth = 120;
      }
      else if (this.authorisationTarget.startsWith('amazon')) {
        this.authorisationTarget = "amazon";
        this.salesChannel = "Amazon";
        this.salesChannelLogo = "assets/Amazon_logo_black.svg";
        this.logoHeight = 50;
        this.logoWidth = 166;
      }

      else if (this.authorisationTarget.startsWith('shopify')) {
        this.authorisationTarget = "shopify";
        this.salesChannel = "Shopify";
        this.salesChannelLogo = "assets/Shopify_logo_black.svg";
        this.logoHeight = 50;
        this.logoWidth = 170;
      }

      urlParts[1].split('&').forEach(p => {
        if (p.startsWith("state=")) {
          this.company = p.split('=')[1].split('~')[0];
        }
      });

      this.whitelabelService.updateLayoutFromCompany(this.company);
    });
  }

  ngOnInit() {

    console.log('ApplicationComponent initializing...');

    this.completed = true;

    this.sub = this.route.params.subscribe(params => {
      this.accountant = params['username'];
      this.language = params['language'];

      if (this.language == null) {
        this.language = 'en';
      }

      this.translateService.use(this.language);

      this.languageSub = this.languageService.languageChanged$.subscribe(language => { this.updateTranslation(); });

      this.processingMessage = this.translateService.instant('connectingMessageWithSalesChannel');
      this.completedMessage = util.format(this.translateService.instant('completedMessageWithSalesChannel'), this.salesChannel);
      this.failedMessage = this.translateService.instant('connectingMessageWithSalesChannel');


      var localUrlPieces = this.route.snapshot['_routerState'].url.toString().split('/');
      var urlParameters = localUrlPieces[localUrlPieces.length - 1];;

      console.log(`Parameters 1: ${urlParameters}`);

      var queryParams = urlParameters.split(/%3F/g)[1].split('&');
      var queryParamsList: { [key: string]: string } = {};

      var updatedUrlParameters = '';
      for (const param of queryParams) {

        var [key, value] = param.split(/%3D/g);

        queryParamsList[key] = value;

        var decodedValue = decodeURIComponent(value);
        var decodedKey = decodeURIComponent(key);

        console.log(`Key: ${decodedKey}, Value: ${decodedValue}`);

        if (decodedValue === 'undefined') {
          updatedUrlParameters += `${decodedKey}&`
        }
        else {
          updatedUrlParameters += `${decodedKey}=${decodedValue}&`
        }
      }

      urlParameters = this.authorisationTarget + '?' + updatedUrlParameters.slice(0, -1)

      console.log(`Parameters 2: ${urlParameters}`);
      console.log(`Auth target: ${this.authorisationTarget}`);

      if (this.authorisationTarget === 'amazon') {

        console.log(`Calling Amazon get App Connection details... param: ${updatedUrlParameters.slice(0, -1)}`);
      }
      else if (this.authorisationTarget === 'xero') {

        console.log(`Calling Xero get App Connection details... param: ${updatedUrlParameters.slice(0, -1) }`);
      }
      else if (this.authorisationTarget === 'shopify') {

        console.log(`Calling Shopify get App Connection details... param: ${updatedUrlParameters.slice(0, -1)}`);

        this.clientService.getShopifyAppConnection(queryParamsList)
          .pipe(first())
          .subscribe(
            data => {
              var result = data as ShopifyAppConnectionResponse;
              var accountants = result.accountants;

              this.connectedToShopify = result.connectedToShopify;
              this.redirectUrl = result.redirectUrl;

              if (accountants != null && accountants.length > 0) {

                var accountant = accountants[0];

                this.accountantName = accountant.name;
                this.accountantEmail = accountant.email;
              }
            },
            error => {
              this.customErrorMessage = true;
              this.failedMessage = this.translateService.instant('failedMessage');
              this.contactYourAccountantMessage = '(' + error + ')';
              this.requestProcessing = false;
              this.requestFailed = true;
              this.showDetails = true;
            },
            () => {

              if (this.redirectUrl === "" || this.redirectUrl === null || this.redirectUrl === undefined)
              {
                this.complete();
              }
              else
              {
                console.log('redirect to:', this.redirectUrl);
                window.location.href = this.redirectUrl;
              }
            });
      }
  });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    this.languageSub.unsubscribe();
  }
  
  updateLayout()
  {
    this.fontColor = this.whitelabelService.fontColor;
    this.fontFamily = this.whitelabelService.fontFamily;
    this.highlightColor = this.whitelabelService.highlightColor;
    this.toolbarColor = this.whitelabelService.toolbarColor;
    this.contactUsLink = this.whitelabelService.contactUsLink;
  }

  updateTranslation()
  {
    this.processingMessage = this.translateService.instant('connectingMessageWithSalesChannel');
    this.completedMessage = util.format(this.translateService.instant('completedMessageWithSalesChannel'), this.salesChannel);
    this.failedMessage = this.translateService.instant('failedMessage');
  }

  complete()
  {
    if (this.accountantEmail === null || this.accountantEmail === undefined || this.accountantEmail === "")
    {
      this.customErrorMessage = true;
      this.failedMessage = this.translateService.instant('connectedMessageWithoutAccountancy');
      this.requestProcessing = false;
      this.requestFailed = true;

      this.showContactUsLink = true;

      console.log('showContactUsLink:', this.showContactUsLink);
      console.log('contactUsLink:', this.contactUsLink);
      return;
    }
    
    this.completedMessage = this.translateService.instant('connectedMessage');

    this.requestProcessing = false;
    this.requestSuccessful = true;
    this.showDetails = true;
  }
}
