import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, NavigationExtras } from '@angular/router';
import { AuthenticationService } from '../_services/authentication.service';
import { first } from 'rxjs/operators';
import { UserData } from '../_providers/userData';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private userData: UserData) {
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
    var userItem = localStorage.getItem('currentUser')
    if (userItem) {
      this.userData.user = JSON.parse(userItem);

      // logged in so return true
      this.authenticationService.setLoggedIn(true);
      return true;
    }

    this.authenticationService.login("avask", "verili123")
      .pipe(first())
      .subscribe(
        data => { this.router.navigate([state.url]) },
        error => {
          let navigationExtras: NavigationExtras = {
            queryParams: { 'returnUrl': state.url },
          };

          // not logged in so redirect to login page with the return url
          this.router.navigate(['login'], navigationExtras);
        });
    return false;
  }
}
