<div layout="column" layout-align="center center" layout-fill="layout-fill" style="height: 100vh;">

  <div class="container" fxLayoutAlign="center center" style="display: inline-flex; align-items: center; min-height: calc(100vh - 96px); flex-direction: row">
      <div class="text-center" style="font-size: 2em; color: #083d5a; margin-bottom: 3em">
          <div *ngIf="requestProcessing" [ngStyle]="{'color': fontColor}" style="font-weight: 300">
              <div style="display: inline-flex; line-height: 32px">
                  <mat-progress-spinner mode="indeterminate" diameter="32" [style.stroke]="toolbarColor" style="margin-right: 1em;"></mat-progress-spinner>
                  <span [innerHTML]="processingMessage"></span>
              </div>
          </div>
          <div *ngIf="requestSuccessful" [ngStyle]="{'color': fontColor}" style="font-weight: 300">
              <span [innerHTML]="completedMessage"></span>
              <br/>
              <br/>
              <div *ngIf="showDetails" style="margin-left: 6.25em; font-size: 20px">
                  <div style="text-align: left; margin-left: -40px">
                      {{ 'Account Manager' | translate }}:<br/>
                  </div>
                  <div style="text-align: left">
                      <strong>{{ accountantName }}</strong><br/>
                      <i>
                          <a [href]="'mailto:' + accountantEmail" [ngStyle]="{'color': fontColor}">{{ accountantEmail }}</a>
                      </i>
                  </div>
              </div>
          </div>
          <div *ngIf="requestFailed">
              <span style="color: #800000" [innerHTML]="failedMessage"></span>
              <br/>
              <div *ngIf="showDetails" style="font-size: 18px">
                  <span [innerHTML]="contactYourAccountantMessage"></span>
              </div>
          </div>
          <div *ngIf="showContactUsLink">
              <a [href]="contactUsLink" target="_blank"
                 style="color: initial; text-decoration: underline; font-size: initial; font-weight: normal;">contact us</a>
          </div>
      </div>
  </div>

  <div id="bottomRightText" style="position: fixed; bottom: 0; right: 10px; padding: 10px; background-color: transparent; color: white; font-size: 10px;">
    <a href='https://vescati.co.uk' target="_blank">
      <img width='190' src='https://verilistorage2.blob.core.windows.net/whitelabel/EcommerceFusionLogo.png' alt='Ecommerce Fusion'>
    </a>
  </div>
</div>
