<div layout="column" layout-align="center center" layout-fill="layout-fill" style="height: 100vh;">

  <div fxLayout="column" fxLayoutAlign="center center">
    <mat-card class="registration-card">
      <mat-card-header>
        <mat-card-title flex fxLayoutAlign="center none">{{ 'Registration' | translate }}</mat-card-title>
      </mat-card-header>

      <mat-card-content>
        <form [formGroup]="registrationForm" (ngSubmit)="onSubmit()">

          <div class="form-group">
            <div class="form-group" [formGroup]="amazonGroup" fxLayoutGap="20px">
              <div layout="row" flex fxLayoutAlign="center none">
                <a mat-stroked-button color="primary" href="{{ 'https://sellercentral.amazon.co.uk/gp/mws/registration/register.html?signInPageDisplayed=1&devAuth=1&developerName=Vescati%20(Verili)&devMWSAccountId=3990-8830-8654' | translate}}"
                   target="_blank">{{ 'Sign in to Amazon to get the Seller Id and MWS Token' | translate }}</a>
                <button mat-icon-button (click)="showHelp()">
                  <mat-icon mat-list-icon>help_outline</mat-icon>
                </button>
              </div>

              <mat-form-field floatLabel>
                <input matInput placeholder="{{ 'Amazon Seller Id / Merchant Token' | translate }}" formControlName="amazonSellerId" required>
                <mat-error *ngIf="a.amazonSellerId.invalid">{{ 'Please enter an Amazon Seller Id' | translate }}</mat-error>
              </mat-form-field>

              <mat-form-field floatLabel>
                <input matInput placeholder="{{ 'MWS authorisation token' | translate }}" [formControl]="a.amazonAuthorisationToken" required>
                <mat-error *ngIf="a.amazonAuthorisationToken.invalid">{{getSellerIdErrorMessage()}}</mat-error>
              </mat-form-field>

              <mat-error *ngIf="amazonGroup.errors?.connectionFailed">{{ 'Invalid Seller Id / MWS Token combination' | translate }}</mat-error>
            </div>
          </div>

          <div class="form-group">
            <mat-form-field floatLabel>
              <input matInput placeholder="{{ 'Company name' | translate }}" [formControl]="f.name" aria-required="false">
            </mat-form-field>
          </div>

          <div class="form-group">
            <mat-form-field floatLabel>
              <input matInput placeholder="{{ 'Email' | translate }}" [formControl]="f.email" required>
              <mat-error *ngIf="f.email.invalid">{{getEmailErrorMessage()}}</mat-error>
            </mat-form-field>
          </div>

          <!--<div *ngIf="newClient" class="form-group" [formGroup]="passwordGroup">
    <mat-form-field floatLabel>
      <div class="form-group">
        <input matInput placeholder="{{ 'New password' | translate }}" type="password" formControlName="password">
      </div>
      <mat-error *ngIf="p.password.errors?.required && p.password.touched">{{ 'Password is required'| translate }}</mat-error>
    </mat-form-field>

    <mat-form-field floatLabel>
      <div class="form-group">
        <input matInput placeholder="{{ 'Confirm new password' | translate }}" type="password" formControlName="confirmPassword">
      </div>
      <mat-error *ngIf="p.confirmPassword.errors?.required && p.confirmPassword.touched">{{ 'Confirm password is required' | translate }}</mat-error>
    </mat-form-field>

    <mat-error *ngIf="passwordGroup.errors?.passwordsAreDifferent">{{ 'Passwords must match' | translate }}</mat-error>
  </div>-->

          <div margin-top="100">
            <div class="form-group" style="margin-right: 20px; float: right">
              <button [disabled]="!registrationForm.valid" class="btn btn-primary">{{ getButtonText() }}</button>
              <img *ngIf="submitted" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
            </div>
          </div>

        </form>
      </mat-card-content>
    </mat-card>
  </div>
</div>
