<div class="overlay-content">
  <div class="spinner-wrapper" *ngIf="loading">
    <mat-spinner></mat-spinner>
  </div>

  <mat-horizontal-stepper class="help-card" labelPosition="bottom" #stepper>
    <mat-step>
      <ng-template matStepLabel>{{ 'DATA USAGE' | translate }}</ng-template>
      <p>
        {{ 'We take data usage and storage very seriously. We only access data from your Amazon account that is absolutely necessary in order to process your international VAT.' | translate }}
      </p>
      <mat-list>
        <h3 mat-subheader>{{ 'Data we access:' | translate }}</h3>
        <mat-list-item>
          <mat-icon mat-list-icon>show_chart</mat-icon>
          <h4 mat-line>{{ 'Amazon VAT Transactions Report' | translate }}</h4>
          <div mat-line>
            <p>{{ 'This is the information necessary for us to compile your VAT liability and is just your sales data that you can freely download from Amazon in their report.' | translate }}</p>
          </div>
        </mat-list-item>
        <mat-divider></mat-divider>
      </mat-list>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>{{ 'AMAZON CONNECTION' | translate }}</ng-template>
      <mat-vertical-stepper>
        <mat-step>
          <ng-template matStepLabel>{{ 'Step 1' | translate }}</ng-template>
          <p>
            {{ "By clicking on the 'Sign in to Amazon to get the Seller Id and MWS Token' button," | translate }}<br />
            {{ 'you can authorise us to download reports automatically directly from Amazon.' | translate }}
          </p>
          <img src="{{ 'https://verilistorage2.blob.core.windows.net/userguide/AMAZON_Auth.png' | translate }}" mat-card-image  alt="{{ 'Step 1' | translate }}" title="{{ 'Step 1' | translate }}" />
        </mat-step>
        <mat-step>
          <ng-template matStepLabel>{{ 'Step 2' | translate }}</ng-template>
          <p>
            {{ 'Please select the required checkbox as shown below to continue:' | translate }}
          </p>
          <img src="{{ 'https://verilistorage2.blob.core.windows.net/userguide/AMAZON_ClickNext.png' | translate }}" alt="{{ 'Step 2' | translate }}" title="{{ 'Step 2' | translate }}" />
        </mat-step>
        <mat-step>
          <ng-template matStepLabel>{{ 'Step 3' | translate }}</ng-template>
          <p>
            {{ 'After the successful authorisation please copy the Seller Id and custom MWS Authorisation Token (highlighted below) and paste into the registration form.' | translate }}
          </p>
          <img src="{{ 'https://verilistorage2.blob.core.windows.net/userguide/AMAZON_Congrat.png' | translate }}" alt="{{ 'Step 3' | translate }}" title="{{ 'Step 3' | translate }}" />
        </mat-step>
      </mat-vertical-stepper>
    </mat-step>
  </mat-horizontal-stepper>

</div>
