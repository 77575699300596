import { Injectable, Inject } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { Whitelabel } from '../_models/whitelabel';

@Injectable({
  providedIn: 'root'
})
export class WhitelabelService {
  private baseUrl: string;
  private _logo: string;
  private _toolbarColor: string;
  private _fontFamily: string;
  private _fontColor: string;
  private _highlightColor: string;
  private _backgroundColor: string;
  private _contactUsLink: string;

  private layoutChanged = new Subject();

  layoutChanged$ = this.layoutChanged.asObservable();

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.baseUrl = baseUrl;
 }

  // Method to get the logo value
  get logo(): string {
    return this._logo;
  }

  // Method to get the toolbar color value
  get toolbarColor(): string {
    return this._toolbarColor;
  }

  // Method to get the font family value
  get fontFamily(): string {
    return this._fontFamily;
  }

  // Method to get the font family value
  get highlightColor(): string {
    return this._highlightColor;
  }

  // Method to get the font color value
  get fontColor(): string {
    return this._fontColor;
  }

  get backgroundColor(): string {
    return this._backgroundColor;
  }

  get contactUsLink(): string {
    return this._contactUsLink;
  }

  updateLayoutFromCompany(company: string) {

    const companyParam = company.trim() === ''
      ? 'default'
      : encodeURIComponent(company).replace('_', '').trim();

    console.info(`Fetching whitelabel data for ${companyParam}...`);

    this.http.get<Whitelabel>(`${this.baseUrl}/whitelabel/updateLayoutFromCompany/${companyParam}`).subscribe(
      (whitelabel: Whitelabel) => {
        this._logo = `<a href='${whitelabel.logoLink}' target='_blank'><img width='${whitelabel.logoWidth}' src='${whitelabel.logoImage}' alt='${whitelabel.logoAlt}'></a>`;
        this._toolbarColor = whitelabel.toolbarColor;
        this._fontFamily = whitelabel.fontFamily;
        this._fontColor = whitelabel.fontColor;
        this._highlightColor = whitelabel.highlightColor;
        this._backgroundColor = whitelabel.backgroundColor;
        this._contactUsLink = whitelabel.contactUsLink;

        this.layoutChanged.next();
      },
      error => {
        console.error('Error fetching whitelabel data:', error);
      }
    );
  }
}
